export const PUSH_TYPE = {
  INSTANTLY: 1,
  DELAY: 2,
};
export const PUSH_TYPE_OPTIONS = Object.entries(PUSH_TYPE).map(([key, value]) => {
  return { value, text: key };
});

export const OVERLAP_TYPE = {
  MULTIPLE: 1,
  FIRST_ONE: 2,
  // LAST_ONE: 2,
};
export const OVERLAP_TYPE_OPTIONS = Object.entries(OVERLAP_TYPE).map(([key, value]) => {
  return { value, text: key };
});
